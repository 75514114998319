<template>
    <div class="navBar"> 
        <van-nav-bar
        style="background: #0097ff;"
            :fixed="fixed"
            z-index="9999"
            :title="title"
            :left-text="leftText"
            :right-text="rightText"
            :left-arrow="leftArrow"
            @click-left="onClickLeft"
            @click-right="onClickRight"
        />
    </div>
</template>

<script>
import { NavBar } from 'vant';
export default {
    props: {
        title: {
            type: String,
            default: '公积金查询服务'
        },
        fixed: {
            type: Boolean,
            default: true
        },
        leftText: {
            type: String,
            default: ''
        },
        rightText: {
            type: String,
            default: ''
        },
        leftArrow: {
            type: Boolean,
            default: false
        }
    },
    components: {
        [NavBar.name]: NavBar
    },
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
        onClickLeft(e) { 
            this.$router.go(-1)//返回上一层
        },
        onClickRight() { },
    }
};
</script>

<style  lang="scss">
    .navBar{
        height:1.22667rem;
        font-size: 30px;
        .van-nav-bar__text{
            color: rgb(255, 255, 255);
        }
        .van-icon{
            color: rgb(255, 255, 255);  
        }
    }
   .navBar .van-ellipsis {
		color: rgb(255, 255, 255);
	 } 
</style>
